import React from 'react'
import { Button, Spinner, Row, Col, ListGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { MapPin, Phone, Xmark } from 'iconoir-react';

import { useAppContext } from '../context';
import StepLayout from '../components/step_layout';
import { api_get } from '../api/api';
import BranchMap from '../components/branch_map';

import '../App.css';


function BranchSelectionPage() {
    const navigate = useNavigate();

    const { state, setState } = useAppContext();
    const [branches, setBranches] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [selectedBranch, setSelectedBranch] = React.useState(null);

    React.useEffect(() => {
        retrieveBranches(state.service)
            .then(x => {
                setBranches(x);
                if(state.branch !== null)
                    setSelectedBranch(state.branch);
            })
            .catch(x=> setError(x));
    }, []);

    React.useEffect(() => {
        if(state.step !== 4)
            setState(prev => ({...prev, step: 4}));
    }, [state]);

    const retrieveBranches = async (service) => {
        return await api_get(`/service/${service.id}/branches`, null, true);
    }

    const saveSelection = (value) =>
    {
        console.log(`User selected branch: ${value}`);
        setState(prev => ({...prev, branch: selectedBranch}));
    };

    const getMarkers = (branches) =>
    {
        return branches.map((branch, idx) => {
            return {
                id: branch.id,
                lon: branch.longitude,
                lat: branch.latitude,
                text: branch.name
            }
        });
    };

    if(state.initialized === false)
        navigate('/');

    return (
        <StepLayout>
            <p className="fw-bold fs-5">
                Select branch
            </p>
            <p className="gray-txt">
                Which branch would you like to visit?
            </p>

            {!error && !branches &&
                <div className="text-center my-5">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }

            {error &&
                <>
                    <p>
                        <strong>Request failed. Please try again later</strong>
                    </p>
                    <p className="small">
                        {error.message}
                    </p>
                </>
            }

            {branches &&
                <div className="d-flex">
                    <ListGroup className="me-3" style={{ maxWidth: '200px' }}>
                    {branches.map((branch) => (
                        <ListGroup.Item
                        className="map-list-item"
                        key={branch.id} 
                        action 
                        onClick={() => setSelectedBranch(branch)}
                        active={selectedBranch && selectedBranch.id === branch.id}
                        >
                            <div className="fw-bold small">
                                {branch.name}
                            </div>
                            <div className="small">
                                {branch.address}
                            </div>
                            
                        </ListGroup.Item>
                    ))}
                    </ListGroup>

                    <div style={{ flexGrow: 1, position: 'relative' }}>
                        <BranchMap markers={getMarkers(branches)} onMarkerClick={(marker) => setSelectedBranch(branches.find(b => b.id === marker.id))}/>
                        {selectedBranch && (
                            <div className="map-overlay" d-flex flex-column>
                                <Row className="mb-3">
                                    <Col className="d-flex align-items-center">
                                        <h5 className="my-0">{selectedBranch.name}</h5>
                                    </Col>
                                    <Col xs="auto" className="d-flex align-items-center">
                                        <button className='invisible-button' onClick={() => setSelectedBranch(null)}>
                                            <Xmark width={30} height={30}/>
                                        </button>
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col xs="auto">
                                        <MapPin />
                                    </Col>
                                    <Col>
                                        {selectedBranch.address}
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col xs="auto">
                                        <Phone />
                                    </Col>
                                    <Col>
                                        {selectedBranch.phone}
                                    </Col>
                                </Row>
                                
                                <Link to="/date">
                                    <Button variant="light" className="action-app-btn w-100 pt-2" onClick={() => saveSelection()}>
                                        Select this branch
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            }
        </StepLayout>
    )
}

export default BranchSelectionPage;
