import React from 'react';
import { Button, Row, Col, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { User, City, Calendar, CheckCircleSolid, XmarkCircleSolid } from 'iconoir-react';
import { Formik, Field, ErrorMessage } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import { api_get, api_post } from '../api/api';
import { ApiErrorType } from '../api/exceptions';
import { useAppContext } from '../context';
import settings from "../settings.json";

import '../App.css';

function WelcomePage() {
    const { state, setState } = useAppContext();
    const [selectedEvent, setSelectedEvent] = React.useState(null);
    const [show, setShow] = React.useState(false);
    const [eventError, setEventError] = React.useState(null);
    const [cancelError, setCancelError] = React.useState(null);

    React.useEffect(() => {
        setState(prev => ({...prev, initialized: true}));
    }, []);

    const validateEvent = async (eventNumber, phoneNumber) => {
        return await api_get('/event/validate', {EventNumber: eventNumber, PhoneNumber: phoneNumber}, true);
    };

    const cancelEvent = async (event_id) => {
        return await api_post(`/event/{event_id}/cancel`, null, true);
    }

    const getBranch = async (service_id, branch_id) => {
        return (await api_get(`/service/${service_id}/branches`, null, true)).find(b => b.id === branch_id);
    }

    const getService = async (service_id) => {
        return (await api_get(`/service/${service_id}`, null, true));
    }

    const showConfirmation = async (values) => {
        var phoneNumber = values.phoneNumber;
        if(phoneNumber.includes('+') === false)
        {
           phoneNumber = settings.DEFAULT_PHONE_PREFIX + phoneNumber; 
        }
        var event = await validateEvent(values.bookingCode, phoneNumber);
        event.service = await getService(event.services[0]);
        event.branch = await getBranch(event.services[0], event.branchId);
        console.log(event);
        setSelectedEvent(event);
        setShow(true);
    };

    
    const saveSelection = (value) =>
    {
        console.log(`User selected category : ${value}`);
        setState(prev => ({...prev, userIsDigital: value}));
    };


    const validationSchema = Yup.object().shape({
        bookingCode: Yup.string().matches(/^\d{7}$/, 'Invalid booking code').required('Booking code is required'),
        phoneNumber: Yup.string().required('Phone number is required')
      });


    return (
        <>
            <Row className="flex-grow-1 justify-content-center align-items-center pb-5">
                <Col sm={12} md={6} lg={6} xl={3} className="d-flex flex-column justify-content-center align-items-center border-end">
                    <Row>
                        <Col className='mb-3'>
                            <p className="fw-bold fs-5 mb-0">New appointment</p>
                            <p className="gray-txt">
                                Please choose in order to continue with booking your appointment
                            </p>
                            <div className="d-flex justify-content-center gap-5 mt-5">
                                <Link to="/service">
                                    <Button variant="light" className="app-btn square-btn shadow-btn" onClick={() => saveSelection("individual")}>
                                        <User height={36} width={36}/><br/>
                                        Individual
                                    </Button>
                                </Link>
                                <Link to="/service">
                                    <Button variant="light" className="app-btn square-btn shadow-btn" onClick={() => saveSelection("company")}>
                                        <City height={36} width={36}/><br/>
                                        Company
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                        <Row>
                            <Col className='mt-3'>
                                <p className="fw-bold fs-5 mb-0">Change or delete appointment</p>
                                <p className="gray-txt">
                                    Fill in the booking code you were given and the mobile number you had declared
                                </p>
                                <div className="d-flex justify-content-center mt-4">
                                    <Formik
                                        initialValues={{ bookingCode: '', phoneNumber: '' }}
                                        validationSchema={validationSchema}
                                        onSubmit={async (values, { setSubmitting, setErrors}) => {
                                            setEventError(null);
                                            try {
                                                await showConfirmation(values);
                                            }
                                            catch(err) {
                                                if(err.name === 'ApiError') {
                                                    console.log(err);
                                                    if(err.errors != null)
                                                    {
                                                        setErrors({
                                                            bookingCode: err.errors['EventNumber'],
                                                            phoneNumber: err.errors['PhoneNumber'],
                                                        });
                                                    }
                                                    else if(err.code === ApiErrorType.OBJECT_NOT_FOUND)
                                                    {
                                                        setEventError('Appointment not found');
                                                    }
                                                }
                                            }
                                            
                                        }}>
                                        {({handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, dirty}) => (
                                            <Form noValidate onSubmit={handleSubmit} className="w-100">
                                                <Row className="mb-3">
                                                    <Col>
                                                        <Form.Group controlId="bookingCode">
                                                            <Form.Label>Booking code</Form.Label>
                                                            <Field
                                                                as={Form.Control}
                                                                type="text"
                                                                name="bookingCode"
                                                                value={values.bookingCode}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                isValid={touched.bookingCode && !errors.bookingCode}
                                                                isInvalid={!!errors.bookingCode}
                                                            />
                                                            <ErrorMessage name="bookingCode" component={Form.Control.Feedback} type="invalid" />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col>
                                                        <Form.Group controlId="phoneNumber">
                                                            <Form.Label>Phone Number</Form.Label>
                                                            <Field
                                                                as={Form.Control}
                                                                type="text"
                                                                name="phoneNumber"
                                                                value={values.phoneNumber}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                isValid={touched.phoneNumber && !errors.phoneNumber}
                                                                isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                                                            />
                                                            <ErrorMessage name="phoneNumber" component={Form.Control.Feedback} type="invalid" />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                {eventError &&
                                                    <Row>
                                                        <Col className="text-center mb-2">
                                                            <p className="text-danger">
                                                                {eventError}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                }
                                                <Row className='mb-2'>
                                                    <Col>
                                                        <Button variant="light" className="action-app-btn mb-2 mt-3 px-4 py-2"
                                                            disabled={!(isValid && dirty)}
                                                            onClick={handleSubmit}>
                                                            Cancel appointment
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </Col>
                        </Row>
                    </Row>
                </Col>
                <Col sm={12} md={6} lg={6} xl={3} className="d-flex flex-column justify-content-center align-items-center">

                </Col>
            </Row>

            <Modal show={show} onHide={() => setShow(false)} centered>
                <Modal.Header closeButton>
                    Appointment details
                </Modal.Header>
                <Modal.Body>
                        {selectedEvent &&
                            <>
                                <Row>
                                    <Col className="text-center">
                                        <div className="red-circle mb-2">
                                            <Calendar className="icon-color-white" />                                
                                        </div>
                                        <div className="gray-txt">
                                            Your appointment
                                        </div>
                                        <div className="fw-bold">
                                            {moment(selectedEvent.start).format('MMMM D, YYYY - HH:mm')}
                                        </div>
                                        <div className="gray-txt">
                                            Service: <span className='fw-bold'>{selectedEvent.service.name}</span>
                                        </div>
                                        <div className="gray-txt">
                                            Branch: <span className='fw-bold'>{selectedEvent.branch.address}</span>
                                        </div>
                                    </Col>
                                </Row>
                                { cancelError &&
                                    <Row className='pt-4'>
                                        <Col className="text-center">
                                            <div>
                                                <strong>Request failed. Please try again later</strong>
                                            </div>
                                            <div className="small">
                                                {cancelError.message}
                                            </div>
                                        </Col>
                                    </Row>
                                }
                                <Row className='pt-4'>
                                    <Button
                                        variant="light"
                                        className="action-app-btn"
                                        onClick={async () => {
                                            setCancelError(null);
                                            try {
                                                await cancelEvent(selectedEvent.id);
                                                setShow(false);
                                            } catch(err) {
                                                setCancelError(err);
                                            }

                                        }}>
                                        Confirm cancellation
                                    </Button>
                                </Row>
                            </>
                        }
                </Modal.Body>
            </Modal>
        </>
    );
}

export default WelcomePage;