import React from 'react'
import { Button, Spinner, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from '../context';
import StepLayout from '../components/step_layout';
import { api_get } from '../api/api'
import { VideoCamera, City, Phone, Neighbourhood } from 'iconoir-react';

const channels = [
    {
        "name" : "Branch visit",
        "icon" : <City />,
        "value" : "InBranch"
    },
    {
        "name" : "Video conference",
        "icon" : <VideoCamera />,
        "value" : "VideoCall"
    },
    {
        "name" : "Phone call",
        "icon" : <Phone />,
        "value" : "PhoneCall"
    },
    {
        "name" : "Customer premises",
        "icon" : <Neighbourhood />,
        "value" : "CustomerPremises"   
    }
];

function ChannelSelectionPage() {
    const navigate = useNavigate();

    const { state, setState } = useAppContext();
    const [eventType, setEventType] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [selectedChannel, setSelectedChannel] = React.useState(null);

    React.useEffect(() => {
        retrieveEventType(state.service.eventTypeId)
            .then(x => {
                setEventType(x);
                if(state.channel !== null)
                    setSelectedChannel(state.channel);
            })
            .catch(x=> setError(x));
    }, []);

    React.useEffect(() => {
        if(state.step !== 3)
            setState(prev => ({...prev, step: 3}));
    }, [state]);

    const retrieveEventType = async (typeId) => {
        return await api_get(`/eventtype/${typeId}`, null, true);
    }

    const saveSelection = () => {
        console.log(`User selected channel: ${selectedChannel}`);
        setState(prev => ({...prev, channel: selectedChannel}));
    };

    const getAvailableChannels = (et) => {
        if(state.userIsDigital === false)
            return et.availableChannels;
        else
            return et.availableChannels.filter(c => c !== 'InBranch');
    };

    if(state.initialized === false)
        navigate('/');

    return (
        <StepLayout>
            <p className="fw-bold fs-5">
                Way of action
            </p>
            <p className="gray-txt">
                On which channel would you like to have your appointment?
            </p>

            {!error && !eventType &&
                <div className="text-center my-5">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }

            {error &&
                <>
                    <p>
                        <strong>Request failed. Please try again later</strong>
                    </p>
                    <p className="small">
                        {error.message}
                    </p>
                </>
            }

            {eventType &&
                <>
                    <div className="d-flex flex-wrap mb-4">
                        {getAvailableChannels(eventType).map((channel, idx) => (
                            <Button
                                className={`px-4 py-3 app-btn outline-btn me-4 mb-2 ${selectedChannel === channel ? 'selected-outline-btn' : ''}`}
                                key={idx}
                                id={`channel-${idx}`}
                                variant="light"
                                value={channel}
                                onClick={(e) => {setSelectedChannel(e.currentTarget.value)}}
                            >
                                <span className='pe-2'>
                                    {channels.find(p => p.value === channel).icon }
                                </span>
                                {channels.find(p => p.value === channel).name }
                            </Button>
                        ))}
                    </div>

                    <Row className='mb-2'>
                        <Col xs={10} sm={8} md={6} lg={4}>
                            <Button variant="light" className="action-app-btn px-4 py-2"
                                    disabled={selectedChannel === null}
                                    onClick={() => {
                                        saveSelection();
                                        navigate("/location");
                                    }}>
                                Continue
                            </Button>
                        </Col>
                    </Row>
                </>
            }
        </StepLayout>
    )
}

export default ChannelSelectionPage;