import { EditPencil } from 'iconoir-react';
import { Link } from 'react-router-dom';

function Step(props) {
    return (        
        <div className="step py-2 px-2 d-flex justify-content-between">
            <span className="fs-6">{props.title}</span>
            <Link to={props.to}><span className="fs-6"><EditPencil/></span></Link>
        </div>
    );
}

export default Step;