import settings from "../settings.json";
import { authProviderFactory } from '../api/auth';
import { HttpError, ApiError, HttpForbiddenError, HttpUnauthorizedError, HttpServerError } from './exceptions';


async function api_call(url, params, reqOptions, auth=false) {
    if(auth) {
        var provider = authProviderFactory.getActiveProvider();
        if(!provider.isLoggedIn()) {
            await provider.login(settings.CLIENT_ID, settings.CLIENT_SECRET);

        }
        else if(!provider.has_access_token() || provider.is_expired()) {
            await provider.refresh_token();
        }

        const auth_headers = provider.get_auth_headers();
        for (var key in auth_headers) {
            reqOptions.headers[key] = auth_headers[key];
        }
    }

    if(params) {
        var query_params = Object.entries(params).map(([key, value]) => 
                            encodeURIComponent(key) + '=' + encodeURIComponent(value)
                        ).join('&');

        url += '?' + query_params;
    }


    const response = await fetch(settings.API_BASE_URL + url, reqOptions);

    var content_type = response.headers.get('Content-Type');
    var result;
    try {
        if(content_type && content_type.includes('application/json'))
            result = await response.json();
        else if (content_type && content_type.includes('text/csv'))
        {
            const header = response.headers.get('Content-Disposition');
            const parts = header.split(';');
            const filename = parts[1].split('=')[1];

            result = {
                'blob' : await response.blob(),
                'filename' : filename
            };
        }
        else
            result = null;
    }
    catch(err) { // Cannot parse data. Set it to empty
        result = null;
    }

    if(!response.ok)
    {
        if(response.status === 403)
            throw new HttpForbiddenError('You are not authorized to perform this action', url, result);
        else if(response.status === 401)
            throw new HttpUnauthorizedError('You are not authorized to view this page', url, result);
        else if(response.status === 500)
            throw new HttpServerError('There was an error processing your request', url, result);
        else
        {
            if(result && result.errorCode)
                throw new ApiError(result.errorCode, result.errorMessage, url, result.errors);
            else
                throw new HttpError(response.status, "HTTP error " + response.status, url, result);
        }
    }

    if(result && result.errorCode && result.errorCode !== 0)
        throw new ApiError(result.errorCode, result.errorMessage, url, result.errors);

    if(result && result.page) {
        return {
            'data' : result.data,
            'page' : result.page,
            'total' : result.totalPages,
            'count' : result.count
        };
    }
    else if(result && result.data)
        return result.data;

    return result;
}


async function api_get(url, params, auth=false) {
    const reqOptions = {
        method: 'GET',
        headers: {}
    }

    return await api_call(url, params, reqOptions, auth);
}


async function api_post(url, params, data, auth=false, json=true) {
    var headers = {};
    if(json == true) {
        data = JSON.stringify(data);
        headers = { 'Content-Type': 'application/json' };
    }

    const reqOptions = {
        method: 'POST',
        headers: headers,
        body: data
    };

    return await api_call(url, params, reqOptions, auth);
}


async function api_put(url, params, data, auth=false, json=true) {
    var headers = {};
    if(json == true) {
        data = JSON.stringify(data);
        headers = { 'Content-Type': 'application/json' };
    }

    const reqOptions = {
        method: 'PUT',
        headers: headers,
        body: data
    };

    return await api_call(url, params, reqOptions, auth);
}


async function api_delete(url, params, auth=false) {
    const reqOptions = {
        method: 'DELETE',
        headers: {}
    }

    return await api_call(url, params, reqOptions, auth);
}

async function api_options(url, params, auth=false) {
    const reqOptions = {
        method: 'OPTIONS',
        headers: {}
    }

    return await api_call(url, params, reqOptions, auth);
}


export { api_get, api_post, api_put, api_options, api_delete };
