import React from 'react'
import { Button, Spinner, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import StepLayout from '../components/step_layout';
import { useAppContext } from '../context';
import { api_get } from '../api/api';
import settings from "../settings.json";

import '../App.css';

function DatetimeSelectionPage() {
    const navigate = useNavigate();
    
    const { state, setState } = useAppContext();
    const [slots, setSlots] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [startDay, setStartDay] = React.useState(moment().startOf('day'));
    const [selectedDay, setSelectedDay] = React.useState(null);
    const [selectedSlot, setSelectedSlot] = React.useState(null);


    React.useEffect(() => {
        var endDay = startDay.clone().add(settings.MAX_FUTURE_APPT_DAYS, 'days');
        retrieveSlots(state.service, startDay.format(), endDay.format())
            .then(x => {
                setSlots(x);
                if(state.timeSlot !== null) {
                    setSelectedSlot(state.timeSlot);
                    setSelectedDay(moment(state.timeSlot.start).startOf('day'));
                }
            })
            .catch(x=> setError(x));
    }, [startDay]);

    React.useEffect(() => {
        if(state.step !== 5)
            setState(prev => ({...prev, step: 5}));
    }, [state]);


    const retrieveSlots = async (service, start, end) => {
        var slots = (await api_get(`/service/${service.id}/availability`, {start: start, end: end}, true)).slots; 
        return slots.map((slot) => {
            return {
                availability: slot.availability,
                start: moment(slot.start),
                duration: moment.duration(slot.duration),
            }
        });
    }

    const getDays = () => {
        var start = startDay.clone();
        var end = startDay.clone().add(settings.MAX_FUTURE_APPT_DAYS, 'days');

        const days = [];
        while (start.isBefore(end)) {
            days.push(start.clone());
            start.add(1, 'days');
          }
          return days;
    };

    const getTimes = (day) => {
        return slots.filter(slot => {
            return slot.start.isSame(day, 'day');
        });
    }

    const saveSelection = () =>
    {
        console.log(`User selected slot: ${selectedSlot.start}`);
        setState(prev => ({...prev, timeSlot: selectedSlot}));
    };

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 7,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 5,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 4,
          slidesToSlide: 1 // optional, default to 1.
        }
    };

    
    if(state.initialized === false)
        navigate('/');

    return (
        <StepLayout>
            <p className="fw-bold fs-5">
                Date and time
            </p>
            <p className="gray-txt">
                Available dates and hours in the branch you chose
            </p>

            {!error && !slots &&
                <div className="text-center my-5">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }

            {error &&
                <>
                    <p>
                        <strong>Request failed. Please try again later</strong>
                    </p>
                    <p className="small">
                        {error.message}
                    </p>
                </>
            }

            {slots &&
                <>
                    <div>
                        Date
                    </div>
                    <Carousel responsive={responsive}>
                        {getDays().map((day, idx) => (
                            <Button 
                                key={idx}
                                id={`dateselection-${idx}`}
                                variant="light"
                                className={`m-2 date-btn ${selectedDay && selectedDay.isSame(day) ? 'selected-outline-btn' : 'outline-btn'}`}
                                value={day.format()}
                                onClick={(e) => {
                                    setSelectedDay(moment(e.currentTarget.value));
                                }}>
                                <div>{day.format('dddd')}</div>
                                <div className="fw-bold fs-3">{day.format('D')}</div>
                                <div>{day.format('MMMM')}</div>
                            </Button>
                        ))}
                    </Carousel>
                </>
            }

            {slots && selectedDay &&
                <>
                    <div>
                        Time
                    </div>
                    <div className="d-flex flex-wrap mb-4 w-100">
                        {getTimes(selectedDay).map((slot, idx) => (
                            <Button 
                                key={idx}
                                id={`timeselection-${idx}`}
                                variant="light"
                                className={`m-2 time-btn ${selectedSlot && selectedSlot.start.isSame(slot.start) ? 'selected-outline-btn' : 'outline-btn'}`}
                                value={slot.start.format()}
                                onClick={(e) => {
                                    setSelectedSlot(slots.find(s => s.start.isSame(moment(e.currentTarget.value))));
                                }}>
                                <div>{slot.start.format("HH:mm")}</div>
                            </Button>
                        ))}
                    </div>

                    <Row className='mb-2'>
                        <Col xs={10} sm={8} md={6} lg={4}>
                            <Button variant="light" className="action-app-btn mb-2 px-4 py-2"
                                disabled={selectedSlot === null}
                                onClick={() => {
                                    saveSelection();
                                    navigate("/details");
                                }}>
                                Continue
                            </Button>
                        </Col>
                    </Row>
                </>
            }
        </StepLayout>
    )
}

export default DatetimeSelectionPage;