import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fix for broken leaflet
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const SetViewToBounds = ({ positions }) => {
    const map = useMap();

    useEffect(() => {
        if (positions.length > 0) {
            const bounds = L.latLngBounds(positions);
            map.fitBounds(bounds);
        }
    }, [positions, map]);

    return null;
};

const BranchMap = ({ markers, onMarkerClick }) => {
    const [mapPosition] = useState([0, 0]); // Default position
    const [zoomLevel] = useState(13); // Default zoom

    return (
        <MapContainer attributionControl={false} center={mapPosition} zoom={zoomLevel} style={{ height: '50vh', width: '100%' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution=""
            />
            {markers.map((marker, idx) => (
                <Marker 
                    key={idx} 
                    position={[marker.lat, marker.lon]}
                    eventHandlers={{
                        click: () => {
                            onMarkerClick(marker);
                        },
                    }}
                />
            ))}

            <SetViewToBounds positions={markers} />
        </MapContainer>
    );
};

export default BranchMap;
