class HttpError extends Error {
    constructor(status, message, url, data) {
        super(message);
        this.name = "HttpError";
        this.status = status;
        this.url = url;
        this.data = data;
    }
}

class HttpServerError extends HttpError {
    constructor(message, url, data) {
        super(500, message, url, data);
        this.name = "HttpServerError";
    }
}

class HttpUnauthorizedError extends HttpError {
    constructor(message, url, data) {
        super(401, message, url, data);
        this.name = "HttpUnauthorizedError";
    }
}

class HttpForbiddenError extends HttpError {
    constructor(message, url, data) {
        super(403, message, url, data);
        this.name = "HttpForbiddenError";
    }
}

const ApiErrorType = {
    NO_ERROR : 0,
    VALIDATION_ERROR : 1,
    OBJECT_NOT_FOUND : 2,
    RELATED_OBJECT_NOT_FOUND : 3,
    DUPLIACTE_OBJECT : 4,
    PROTECTED_OBJECT: 5,
    NO_AVAILABILITY : 10,
    INVALID_PASSWORD : 11,
    PASSWORD_REQUIREMENTS : 12
};

class ApiError extends Error {
    constructor(code, message, url, errors) {
        super(message);
        this.name = "ApiError";
        this.code = code;
        this.message = message;
        this.url = url;
        this.errors = errors;
    }
}


export { HttpError, ApiError, HttpForbiddenError, HttpUnauthorizedError, HttpServerError, ApiErrorType };