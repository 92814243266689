import React from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import StepLayout from '../components/step_layout';
import { useAppContext } from '../context';

function CustomerDetailsSelectionPage() {
    const navigate = useNavigate();
    
    const { state, setState } = useAppContext();

    React.useEffect(() => {
        if(state.step !== 6)
            setState(prev => ({...prev, step: 6}));
    }, [state]);

    const saveSelection = (value) =>
    {
        console.log(`User entered details: ${value}`);
        setState(prev => ({...prev, details: value}));
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        phoneNumber: Yup.string().required('Phone number is required'),
        notes: Yup.string()
      });

    if(state.initialized === false)
        navigate('/');

    return (
        <StepLayout>
            <p className="fw-bold fs-5">
                Your information
            </p>
            <p className="gray-txt">
                Please fill in your information
            </p>

            <Formik
                initialValues={
                    state && state.details ?
                    {
                        firstName: state.details.firstName,
                        lastName: state.details.lastName,
                        email: state.details.email,
                        phoneNumber: state.details.phoneNumber,
                        notes: state.details.notes
                    } : 
                    {
                        firstName : '',
                        lastName : '',
                        email : '',
                        phoneNumber : '',
                        notes : ''
                    }
                }
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    saveSelection(values);
                    setSubmitting(false);
                    navigate("/confirm");
                }}>
                {({handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, dirty}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">

                            <Col md={6}>
                                <Form.Group controlId="firstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Field
                                        as={Form.Control}
                                        type="text"
                                        name="firstName"
                                        value={values.firstName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isValid={touched.firstName && !errors.firstName}
                                        isInvalid={!!errors.firstName}
                                    />
                                    <ErrorMessage name="firstName" component={Form.Control.Feedback} type="invalid" />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="lastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Field
                                        as={Form.Control}
                                        type="text"
                                        name="lastName"
                                        value={values.lastName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isValid={touched.lastName && !errors.lastName}
                                        isInvalid={touched.lastName && !!errors.lastName}
                                    />
                                    <ErrorMessage name="lastName" component={Form.Control.Feedback} type="invalid" />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="phoneNumber">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Field
                                        as={Form.Control}
                                        type="text"
                                        name="phoneNumber"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isValid={touched.phoneNumber && !errors.phoneNumber}
                                        isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                                    />
                                    <ErrorMessage name="phoneNumber" component={Form.Control.Feedback} type="invalid" />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Field
                                        as={Form.Control}
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isValid={touched.email && !errors.email}
                                        isInvalid={touched.email && !!errors.email}
                                    />
                                    <ErrorMessage name="email" component={Form.Control.Feedback} type="invalid" />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <Form.Group controlId="notes">
                                    <Form.Label>Notes (optional)</Form.Label>
                                    <Field
                                        as={Form.Control}
                                        name="notes"
                                        value={values.notes}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isValid={touched.notes && !errors.notes}
                                        isInvalid={touched.notes && !!errors.notes}
                                        textarea
                                        rows={3}
                                    />
                                    <ErrorMessage name="notes" component={Form.Control.Feedback} type="invalid" />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='mb-2'>
                            <Col xs={10} sm={8} md={6} lg={4}>
                                <Button variant="light" className="action-app-btn mb-2 mt-3 px-4 py-2"
                                    disabled={!(isValid)}
                                    onClick={handleSubmit}>
                                    Continue
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </StepLayout>
    )
}

export default CustomerDetailsSelectionPage;