import settings from "../settings.json";
import { api_post } from "./api"
import { HttpError, ApiError } from "./exceptions";
import { NotImplementedError } from "../exceptions";

class TokenAuthProvider {
    ACCESS_TOKEN_KEY = 'access-token';
    REFRESH_TOKEN_KEY = 'refresh-token';
    _fetching_token = false;
    
    async _wait_fetch_unlock() {
        const poll = resolve => {
            if(this._fetching_token === false) resolve();
            else setTimeout(_ => poll(resolve), 250);
        }
        return new Promise(poll);
    }

    isLoggedIn() {
        return this.has_access_token() || this.has_refresh_token();
    }

    has_access_token() {
        let token = localStorage.getItem(this.ACCESS_TOKEN_KEY);
        if(!token)
            return false;
        return true;
    }

    has_refresh_token() {
        let token = localStorage.getItem(this.REFRESH_TOKEN_KEY);
        if(!token)
            return false;
        return true;
    }


    is_expired() {
        let token = localStorage.getItem(this.ACCESS_TOKEN_KEY);
        try {
            let decoded = JSON.parse(atob(token.split('.')[1]));
            if (Date.now() >= decoded.exp * 1000) {
                return true;
            }
        }
        catch {
            return true;
        }

        return false;
    }


    get_auth_headers() {
        return {'Authorization' : 'Bearer ' + localStorage.getItem(this.ACCESS_TOKEN_KEY)};
    }

    get_access_token() {
        return localStorage.getItem(this.ACCESS_TOKEN_KEY);
    }


    logout() {
        localStorage.removeItem(this.ACCESS_TOKEN_KEY);
        localStorage.removeItem(this.REFRESH_TOKEN_KEY);
        authProviderFactory.setDefaultActiveProvider();
    }

    async authenticate(state, code) {
        throw new NotImplementedError();
    }
}


class OIDCProvider extends TokenAuthProvider {
    #OAUTH_STATE_KEY = 'oauth2-state';
    #OAUTH_PKCE_KEY = 'oauth2-pkce';


    randomString(length)
    {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    
        for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
    
        return text;
    }


    async generateCodeChallenge(codeVerifier) {
        var digest = await crypto.subtle.digest("SHA-256",
            new TextEncoder().encode(codeVerifier));

        return window.btoa(String.fromCharCode(...new Uint8Array(digest)))
            .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
    }


    async getAuthURL() {
        var state = this.randomString(40);
        var pkceCodeVerifier = this.randomString(64);
        var pkceCodeChallenge = await this.generateCodeChallenge(pkceCodeVerifier);

        sessionStorage.setItem(this.#OAUTH_STATE_KEY, state);
        sessionStorage.setItem(this.#OAUTH_PKCE_KEY, pkceCodeVerifier);
        return `${this.OIDC_SETTINGS.AUTH_URL}?response_type=code&client_id=${this.OIDC_SETTINGS.CLIENT_ID}&redirect_uri=${encodeURIComponent(this.OIDC_SETTINGS.REDIRECT_URL)}&scope=${this.OIDC_SETTINGS.SCOPE}&code_challenge=${encodeURIComponent(pkceCodeChallenge)}&code_challenge_method=S256&state=${state}`;
    }


    isWaitingAuthentication() {
        var local_state = sessionStorage.getItem(this.#OAUTH_STATE_KEY);
        if(!local_state)
            return false;
        return true;
    }


    async authenticate(state, code) {
        var local_state = sessionStorage.getItem(this.#OAUTH_STATE_KEY);
        if(state !== local_state) {
            console.error(`Invalid state in oauth2 authorization callback [${state}] - [${local_state}]`);
            throw new Error('Invalid state');
        }
        sessionStorage.removeItem(this.#OAUTH_STATE_KEY);

        const data = new URLSearchParams({
            grant_type : "authorization_code",
            code : code,
            client_id : this.OIDC_SETTINGS.CLIENT_ID,
            redirect_uri : this.OIDC_SETTINGS.REDIRECT_URL,
            code_verifier : sessionStorage.getItem(this.#OAUTH_PKCE_KEY)
        });

        var response = null;
        try {
            response = await fetch(this.OIDC_SETTINGS.TOKEN_URL, {method : "POST", body : data});
            response = await response.json();
        }
        catch(err) {
            console.error("Failed to retrieve token: " + err);
            return false;
        }

        localStorage.setItem(this.ACCESS_TOKEN_KEY, response.id_token);
        localStorage.setItem(this.REFRESH_TOKEN_KEY, response.refresh_token);

        sessionStorage.removeItem(this.#OAUTH_STATE_KEY);
        sessionStorage.removeItem(this.#OAUTH_PKCE_KEY);
        return true;
    }


    async refresh_token(force = false) {
        // Implement a lock so that a token is not refreshed multiple times
        if(this._fetching_token === true) 
            await this._wait_fetch_unlock();
        // If token was already refreshed, just return
        if(this.has_access_token() && !this.is_expired() && force == false)
            return true;
        this._fetching_token = true;

        let refresh_token = localStorage.getItem(this.REFRESH_TOKEN_KEY);

        const data = new URLSearchParams({
            grant_type : "refresh_token",
            refresh_token : refresh_token,
            client_id : this.OIDC_SETTINGS.CLIENT_ID
        });

        var response = null;
        try {
            response = await fetch(this.OIDC_SETTINGS.TOKEN_URL, {method : "POST", body : data});
            response = await response.json();
        }
        catch(err) {
            console.error("Failed to retrieve token: " + err);
            this._fetching_token = false;
            return false;
        }

        localStorage.setItem(this.ACCESS_TOKEN_KEY, response.id_token);
        localStorage.setItem(this.REFRESH_TOKEN_KEY, response.refresh_token);

        this._fetching_token = false;
        return true;
    }
}

class AzureProvider extends OIDCProvider {
    OIDC_SETTINGS = settings.OIDC.AZURE;
}



class LocalAuthProvider extends TokenAuthProvider {

    async getAuthURL() {
        return 'login';
    }

    async login(username, password) {
        var response = null;
        try {
            response = await api_post(settings.AUTH_URL, null, {username : username, password: password});
        }
        catch(err) {
            if (err instanceof HttpError) {
                return false;
            }
        }

        localStorage.setItem(this.ACCESS_TOKEN_KEY, response.accessToken);
        localStorage.setItem(this.REFRESH_TOKEN_KEY, response.refreshToken);
        return true;
    }

    async refresh_token(force = false) {
        // Implement a lock so that a token is not refreshed multiple times
        if(this._fetching_token === true)
            await this._wait_fetch_unlock();
        // If token was already refreshed, just return
        if(this.has_access_token() && !this.is_expired() && force == false)
            return true;
        this._fetching_token = true;

        let refresh_token = localStorage.getItem(this.REFRESH_TOKEN_KEY);
        var response = null;
        try {
            response = await api_post(settings.REFRESH_URL, null, {refresh : refresh_token});
        }
        catch(err) {
            if (err instanceof HttpError) {
                if(err.status == 401) {
                    this.logout();
                }
                this._fetching_token = false;
                return false;
            }
        }

        localStorage.setItem(this.ACCESS_TOKEN_KEY, response.accessToken);
        if(response.refreshToken)
            localStorage.setItem(this.REFRESH_TOKEN_KEY, response.refreshToken);
        this._fetching_token = false;
        return true;
    }
}


class AuthProviderFactory {
    #OAUTH_PROVIDER_KEY = 'oauth2-provider';
    #activeProvider = null;

    getActiveProvider() {
        if(!this.#activeProvider) {
            var provider = sessionStorage.getItem(this.#OAUTH_PROVIDER_KEY);
            if(!provider)
                this.setDefaultActiveProvider();
            else
                this.changeActiveProvider(provider);
        }
        return this.#activeProvider;
    }

    setDefaultActiveProvider() {
        this.changeActiveProvider('LocalAuthProvider');
    }


    changeActiveProvider(authProviderClass) {
        switch(authProviderClass)
        {
            case 'LocalAuthProvider':
                this.#activeProvider = new LocalAuthProvider();
                break;
            case 'OIDCProvider':
                this.#activeProvider = new OIDCProvider();
                break;
            case 'AzureProvider':
                this.#activeProvider = new AzureProvider();
                break;
            default:
                throw new TypeError('Invalid AuthProvider class');
        }
        sessionStorage.setItem(this.#OAUTH_PROVIDER_KEY, authProviderClass);
    }
}

var authProviderFactory = new AuthProviderFactory();

export { authProviderFactory, LocalAuthProvider, AzureProvider };
