import { Outlet, Link } from 'react-router-dom';
import { Container, Navbar, Nav, Image } from 'react-bootstrap';

function Layout() {
    return (
        <>
            <Navbar variant="dark" expand="lg" className="px-0 py-0 navbar-custom">
                <Container fluid className='px-0 py-0'>
                    <Navbar.Brand href="#home" className='px-0 py-0'>
                        <Link to="/">
                            <Image src={require('./assets/unicredit.jpg')} height="50" className="px-0 py-0 d-inline-block align-top" alt="Logo" />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                            </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Container fluid className="h-100 d-flex flex-column">
                <Outlet />
            </Container>
        </>
    );
}

export default Layout;