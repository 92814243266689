import React from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Calendar, CheckCircleSolid, XmarkCircleSolid } from 'iconoir-react';

import { api_get, api_post } from '../api/api';
import StepLayout from '../components/step_layout';
import { useAppContext } from '../context';
import settings from "../settings.json";

function ConfirmationPage() {
    const navigate = useNavigate();
    const { state, setState } = useAppContext();

    const [isChecked, setIsChecked] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [response, setResponse] = React.useState(null);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        if(state.step !== 7)
            setState(prev => ({...prev, step: 7}));
    }, [state]);

    const bookAppointment = async () =>
    {
        var phoneNumber = state.details.phoneNumber;
        if(state.details.phoneNumber.includes('+') === false)
        {
            phoneNumber = settings.DEFAULT_PHONE_PREFIX + phoneNumber; 
        }

        var data = {
            "start" : state.timeSlot.start,
            "channel" : state.channel,
            "branchId" : state.branch.id,
            "description" : state.details.notes,
            "newGuests" : [
                {
                    "firstName" : state.details.firstName,
                    "lastName" : state.details.lastName,
                    "phoneNumber" : phoneNumber,
                    "email" : state.details.email
                }
            ]
        }

        console.log(`Booking appointment: ${data}`);
        try {
            var resp = await api_post(`/service/${state.service.id}/book`, null, data, true);
            setResponse(resp);
            setError(null);
            console.log("Successfully booked appointment");
        }
        catch(err) {
            console.log(`Appointment booking failed: ${err}`);
            setResponse(null);
            setError(err);
        }
    };

    if(state.initialized === false)
        navigate('/');

    return (
        <>
            <StepLayout>
                <Row>
                    <Col>
                    <p className="fw-bold fs-5">
                        Confirmation
                    </p>
                    </Col>
                </Row>
                <Row>
                    <Col className="gray-txt">
                        <p>
                            Please confirm your appointment
                        </p>
                        <div>
                            <span>Service:</span> <span className="fw-bold">{state.service.name}</span>
                        </div>
                        <div>
                            <span>Name:</span> <span className="fw-bold">{state.details.firstName} {state.details.lastName}</span>
                        </div>
                        <div>
                            <span>Phone:</span> <span className="fw-bold">{state.details.phoneNumber}</span>
                        </div>
                        <div>
                            <span>Email:</span> <span className="fw-bold">{state.details.email}</span>
                        </div>
                    </Col>
                    <Col className="text-center">
                        <div className="red-circle mb-2">
                            <Calendar className="icon-color-white" />                                
                        </div>
                        <div className="gray-txt">
                            Your appointment
                        </div>
                        <div className="gray-txt">
                            {state.timeSlot.start.format('MMMM D, YYYY - HH:mm')}
                        </div>
                        <div className="gray-txt">
                            Branch: {state.branch.name}
                        </div>
                        <div className="gray-txt">
                            {state.branch.address}
                        </div>
                    </Col>
                </Row>
                <Row className='mt-4 py-2' id="confirmation-box">
                    <Col sm={9} md={8} lg={9} xl={9} className="small">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="confirmCheckbox"
                                checked={isChecked}
                                onChange={(e) => setIsChecked(e.target.checked)}/>
                            <label className="form-check-label" htmlFor="confirmCheckbox">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis turpis ac lorem fringilla auctor sit amet id nunc. Cras eget cursus magna. Cras consequat tincidunt venenatis. Aenean magna ligula, egestas in lacus sit amet, vehicula finibus velit. Nunc orci mi, euismod at ipsum viverra, cursus gravida tellus.
                            </label>
                        </div>
                    </Col>
                    <Col sm={3} md={3} lg={3} xl={3} className="d-flex align-items-center">
                        <Button 
                            variant="light"
                            className="action-app-btn"
                            disabled={!isChecked}
                            onClick={async () => {
                                await bookAppointment();
                                setShow(true);
                            }}>
                            Confirm appointment
                        </Button>
                    </Col>
                </Row>
            </StepLayout>
            <Modal show={show} onHide={() => error == null ? navigate('/') : setShow(false)} centered>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col className="text-center">
                                {response && !error &&
                                    <>
                                        <div className='mb-2'>
                                            <CheckCircleSolid height={48} width={48} color="green"/>
                                        </div>
                                        <div className="fs-5 mb-2">
                                            Your appointment has been booked!
                                        </div>
                                        <div className="fs-5">
                                            Booking code: <span className="fw-bold">{response.eventNumber}</span>
                                        </div>
                                    </>
                                }
                                {error && !response &&
                                    <>
                                        <div className='mb-2'>
                                            <XmarkCircleSolid height={48} width={48} color="red"/>
                                        </div>
                                        <div className="fs-6 mb-2">
                                            There was an error processing your request<br/>
                                            Please try again later
                                        </div>
                                        <div className="small">
                                            {error.message}
                                        </div>
                                    </>
                                }
                            </Col>
                            
                        </Row>
                    </Modal.Body>
                </Modal>
        </>
    );
}

export default ConfirmationPage;