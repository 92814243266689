import React from 'react'
import { Button, Spinner, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


import { useAppContext } from '../context';
import StepLayout from '../components/step_layout';
import { api_get } from '../api/api';

import '../App.css';


function ServiceSelectionPage() {
    const navigate = useNavigate();

    const { state, setState } = useAppContext();
    const [services, setServices] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [selectedService, setSelectedService] = React.useState(null);

    React.useEffect(() => {
        retrieveServices(false).then(x => {
            setServices(x);
            if(state.service !== null)
                setSelectedService(state.service.id);
        }).catch(x=> setError(x));
    }, []);

    React.useEffect(() => {
        if(state.step !== 0)
            setState(prev => ({...prev, step: 0}));
    }, [state]);

    const retrieveServices = async (isIndividual) => {
        return (await api_get('/service/', null, true)).data;
    }

    const saveSelection = () =>
    {
        var service = services.find(s => s.id === selectedService);
        console.log(`User selected service: ${service.name}`);
        setState(prev => ({...prev, service: service}));
    };

    if(state.initialized === false)
        navigate('/');

    return (
        <StepLayout>
            <p className="fw-bold fs-5">
                Select Service
            </p>
            <p className="gray-txt">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Nunc quis turpis ac lorem fringilla auctor sit amet id nunc.
                Cras eget cursus magna. Cras consequat tincidunt venenatis.
            </p>

            {!error && !services &&
                <div className="text-center my-5">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }

            {error &&
                <>
                    <p>
                        <strong>Request failed. Please try again later</strong>
                    </p>
                    <p className="small">
                        {error.message}
                    </p>
                </>
            }

            {services &&
                <>
                    <div className="d-flex flex-wrap mb-4">
                        {services.map((service, idx) => (
                            <Button
                                className={`p-4 app-btn outline-btn service-btn me-4 mb-2 ${selectedService === service.id ? 'selected-outline-btn' : ''}`}
                                key={idx}
                                id={`selection-${idx}`}
                                variant="light"
                                value={service.id}
                                onClick={(e) => {setSelectedService(e.currentTarget.value)}}
                            >
                                {service.name}
                            </Button>
                        ))}
                    </div>

                    <Row className='mb-2'>
                        <Col xs={10} sm={8} md={6} lg={4}>
                            <Button variant="light" className="action-app-btn mb-2 px-4 py-2"
                                disabled={selectedService === null}
                                onClick={() => {
                                    saveSelection();
                                    navigate("/relationship");
                                }}>
                                Continue
                            </Button>
                        </Col>
                    </Row>
                </>
            }
        </StepLayout>
    )
}

export default ServiceSelectionPage;