import React from 'react'
import { Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import StepLayout from '../components/step_layout';
import { useAppContext } from '../context';

function DigitalClientSelectionPage() {
    const navigate = useNavigate();
    
    const { state, setState } = useAppContext();
    const [selectedOption, setSelectedOption] = React.useState(null);

    React.useEffect(() => {
        if(state.step !== 2)
            setState(prev => ({...prev, step: 2}));
    }, [state]);

    React.useEffect(() => {
        if(state.userIsDigital !== null)
            setSelectedOption(state.userIsDigital);
    }, []);

    const saveSelection = (value) =>
    {
        console.log(`User selected customer relationship: ${value}`);
        setState(prev => ({...prev, userIsDigital: value}));
    };

    if(state.initialized === false)
        navigate('/');
    
    return (
        <StepLayout>
            <p className="fw-bold fs-5">
                Are you a digital client?
            </p>
            <div className="d-flex gap-3 my-3">
                <Button variant="light" className={`app-btn outline-btn px-4 py-2 ${selectedOption === true ? 'selected-outline-btn' : ''}`}
                        onClick={() => setSelectedOption(true)}>
                    Yes
                </Button>
                <Button variant="light" className={`app-btn outline-btn px-4 py-2 ${selectedOption === false ? 'selected-outline-btn' : ''}`}
                        onClick={() => setSelectedOption(false)}>
                    Not yet
                </Button>
            </div>


            <Row className='mb-2'>
                <Col xs={10} sm={8} md={6} lg={4}>
                    <Button variant="light" className="action-app-btn mb-2 mt-3 px-4 py-2"
                        disabled={selectedOption === null}
                        onClick={() => {
                            saveSelection(selectedOption);
                            navigate("/channel");
                        }}>
                        Continue
                    </Button>
                </Col>
            </Row>
        </StepLayout>
    )
}

export default DigitalClientSelectionPage;