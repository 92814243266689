import { Row, Col } from 'react-bootstrap';
import Step from './step';
import { useAppContext } from '../context';

function StepLayout(props) {
    const { state, setState } = useAppContext();
    
    return (
        <Row className="flex-grow-1 justify-content-center align-items-center">
            <Col sm={12} md={10} lg={8} xl={8} xxl={6} className="d-flex flex-column mb-5">
                <Row className='pb-5'>
                    
                    { state.step > 0 && <div className='mt-2 px-0'><Step title="Select Service" to="/service"/></div>}
                    { state.step > 1 && <div className='mt-2 px-0'><Step title="Bank relations" to="/relationship"/></div>}
                    { state.step > 2 && <div className='mt-2 px-0'><Step title="Are you a digital client" to="/digital"/></div>}
                    { state.step > 3 && <div className='mt-2 px-0'><Step title="Way of action" to="/channel"/></div>}
                    { state.step > 4 && <div className='mt-2 px-0'><Step title="Select branch" to="/location"/></div>}
                    { state.step > 5 && <div className='mt-2 px-0'><Step title="Date and time" to="/date"/></div>}
                    { state.step > 6 && <div className='mt-2 px-0'><Step title="Your information" to="/details"/></div>}
                </Row>
                <Row className='bg-white py-3'>
                    {props.children}
                </Row>
                
            </Col>
        </Row>
    );
}

export default StepLayout;