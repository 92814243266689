import React from 'react'
import { Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import StepLayout from '../components/step_layout';
import { useAppContext } from '../context';


function CustomerRelationshipSelectionPage() {
    const navigate = useNavigate();
    
    const { state, setState } = useAppContext();
    const [selectedOption, setSelectedOption] = React.useState(null);

    const saveSelection = (value) =>
    {
        console.log(`User selected customer relationship: ${value}`);
        setState(prev => ({...prev, userRelationship: value}));
    };

    React.useEffect(() => {
        if(state.userRelationship !== null)
            setSelectedOption(state.userRelationship);
    }, [state]);

    React.useEffect(() => {
        if(state.step !== 1)
            setState(prev => ({...prev, step: 1}));
    }, [state]);

    if(state.initialized === false)
        navigate('/');

    return (
        <StepLayout>
            <p className="fw-bold fs-5">
                Bank relations
            </p>
            <p className="gray-txt">
                Are you a customer of our bank?
            </p>
            <div className="d-flex gap-3 my-3">
                <Button variant="light" className={`app-btn outline-btn px-4 py-2 ${selectedOption === "existing" ? 'selected-outline-btn' : ''}`}
                        onClick={() => setSelectedOption("existing")}>
                    Yes
                </Button>
                <Button variant="light" className={`app-btn outline-btn px-4 py-2 ${selectedOption === "new" ? 'selected-outline-btn' : ''}`}
                        onClick={() => setSelectedOption("new")}>
                    Not yet
                </Button>
            </div>

            <Row className='mb-2'>
                <Col xs={10} sm={8} md={6} lg={4}>
                    <Button variant="light" className="action-app-btn mb-2 mt-3 px-4 py-2"
                            disabled={selectedOption === null}
                            onClick={() => {
                                saveSelection(selectedOption);
                                navigate("/digital");
                            }}>
                        Continue
                    </Button>
                </Col>
            </Row>
        </StepLayout>
    )
}

export default CustomerRelationshipSelectionPage;