import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import * as Pages from './pages';
import Layout from './layout';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return(
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Navigate replace to="welcome" />} />
          <Route path="welcome" element={<Pages.WelcomePage />} />
          <Route path="service" element={<Pages.ServiceSelectionPage />} />
          <Route path="relationship" element={<Pages.CustomerRelationshipSelectionPage />} />
          <Route path="digital" element={<Pages.DigitalClientSelectionPage />} />
          <Route path="channel" element={<Pages.ChannelSelectionPage />} />
          <Route path="location" element={<Pages.BranchSelectionPage />} />

          <Route path="date" element={<Pages.DatetimeSelectionPage />} />
          <Route path="details" element={<Pages.CustomerDetailsSelectionPage />} />
          <Route path="confirm" element={<Pages.ConfirmationPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
