import React, { createContext, useState, useContext } from 'react';


const AppContext = createContext();


export const ContextProvider = ({ children }) => {
  const [state, setState] = useState({
    step: null,
    initialized: false,
    userCategory: null,
    userRelationship: null,
    userIsDigital: null,
    service: null,
    channel: null,
    branch: null,
    timeSlot: null,
    details: null
  });

  const value = { state, setState };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};


export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within a ContextProvider');
  }
  return context;
};